import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import MainNav from '../components/functional/mainNav'
import SingleJob from '../components/functional/singleJob'
import SmallTitle from '../components/functional/smallTitle'
import Layout from '../components/layout'
import { media } from '../utils/Media'

const metaData = {
  title: 'Careers | D E W Y N T E R S',
}

const JobGrid = styled.section`
  width: 100%;
  margin: 1.5rem 0 2rem 0;
  min-height: calc(90vh - 210px);
  .j-g {
    width: 85%;
    margin: var(--auto);
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2rem;
    max-width: 800px;
    @media ${media.sm} {
      grid-template-columns: repeat(2, 1fr);
    }
    @media ${media.xl} {
      grid-gap: 4rem;
    }
    animation: fade-in-bottom 0.6s 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  h1 {
    display: none;
    animation: fade-in-bottom 0.6s 0.15s cubic-bezier(0.39, 0.575, 0.565, 1)
      both;
    @media ${media.sm} {
      display: block;
      width: 90%;
      font-weight: 900;
      margin: var(--auto);
      color: var(--cyan);
      text-align: center;
      font-size: 2.3rem;
      padding: 1.5rem 0 2rem;
    }
    @media ${media.xl} {
      font-size: 2.65rem;
      margin-bottom: 1rem;
    }
  }
  @media ${media.slg} {
    min-height: calc(80vh - 125px);
  }
`
const Careers = ({ data }) => {
  const jobs = data.Jobs.nodes
  return (
    <Layout title={metaData.title}>
      <SmallTitle title="Careers" dnd>
        <h2>
          Want to make different happen? <br /> Come and join our team
        </h2>
      </SmallTitle>
      <MainNav alt />
      <JobGrid>
        <h1 className="m-nd">
          Want to make different happen? <br /> Come and join our team.
        </h1>
        
        <div className="j-g">
          {jobs.map((job, i) => (
            <SingleJob key={i} data={job} />
          ))}
        </div>
      </JobGrid>
    </Layout>
  )
}

export default Careers

export const CareersQuery = graphql`
  query CareersQuery {
    Jobs: allJobsJson {
      nodes {
        title
        link
      }
    }
  }
`


{/* <p style={{
          textAlign: "center",
          padding: "0 2rem"
        }}>Currently no vacancies</p> */}