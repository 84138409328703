import React from 'react'
import styled from 'styled-components'

const SmallTitleStyles = styled.header`
  width: 100%;
  padding-top: 7rem;
  animation: fade-in-bottom 0.6s 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  .s-t {
    width: 85%;
    margin: var(--auto);
    padding-bottom: 1rem;
    h1 {
      font-size: 2rem;
      font-weight: 800;
      letter-spacing: 2px;
      margin: 0;
      color: var(--cyan);
      text-transform: uppercase;
    }
    .text-center {
      text-align: center;
    }
    h2 {
      margin-top: 0.5rem;
      font-size: 2.85rem;
      font-weight: 900;
      line-height: 3.25rem;
    }
  }
`

const SmallTitle = ({ title, children, dnd, tc }) => (
  <SmallTitleStyles className={dnd ? 'd-nd' : ''}>
    <div className="s-t">
      <h1 className={tc ? 'text-center' : ''}>{title}</h1>
      {children || ''}
    </div>
  </SmallTitleStyles>
)

export default SmallTitle
