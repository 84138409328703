import React from 'react'
import styled from 'styled-components'
import HandIcon from 'svgs/Hand.svg'
import {media} from 'utils/Media'

const SingleJobStyles = styled.section`
  width: 100%;
  border: solid 2px transparent;
  transition: all 0.4s ease-in-out;
  padding: 0.5rem;
  cursor: pointer;
  
  &:nth-child(odd) {
    &:hover,
    &:focus {
      border-radius: 5px;
      box-shadow: rgba(0, 228, 225, 0.4) -5px 5px,
        rgba(0, 228, 225, 0.3) -10px 10px, rgba(0, 228, 225, 0.2) -15px 15px,
        rgba(0, 228, 225, 0.1) -20px 20px, rgba(0, 228, 225, 0.05) -25px 25px;
    }
  }

  &:nth-child(even) {
    &:hover,
    &:focus {
      border-radius: 5px;
      box-shadow: rgba(0, 228, 225, 0.4) 5px 5px,
      rgba(0, 228, 225, 0.3) 10px 10px, rgba(0, 228, 225, 0.2) 15px 15px,
      rgba(0, 228, 225, 0.1) 20px 20px, rgba(0, 228, 225, 0.05) 25px 25px;
    }
  }

  &:hover,
  &:active {
    /* background-color: black;
    color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
    /* border: solid 2px var(--cyan); */
  }

  h3 {
    font-weight: 900;
    font-size: 1.65rem;
    font-family: var(--PoppinsBlack);

    @media ${media.sm} {
      font-size: 1.5rem;
    }
  }

  .body {
    p {
      margin-top: 1rem;
    }
  }

  .icons {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${media.sm} {
      justify-content: flex-start;
    }

    svg {
      width: 60px;
      height: 60px;
      fill: var(--cyan);
    }

    p {
      display: flex;
      align-items: center;
      width: 100%;
      padding-left: 1rem;
      font-size: 1.25rem;
      text-transform: uppercase;
    }

    &:nth-child(2) {
      p {
        padding-top: 0.5rem;
      }
    }


    @media ${media.sm} {
      flex-direction: row;

      svg {
        width: 40px;
        height: 40px;
      }

      p {
        width: 100%;
        font-size: 0.95rem;
        line-height: 1;
        padding-left: 0.5rem;
      }
    }
  }
`
const SingleJob = ({data}) => (
    <SingleJobStyles>
        <a
            href={data.link} target="_blank"
        >
            <h3>{data.title}</h3>
            <div className="body" dangerouslySetInnerHTML={{__html: data.body}}/>
            <div className="icons">

                <HandIcon/>
                <p>About the role</p>
            </div>
        </a>
    </SingleJobStyles>
)

export default SingleJob
